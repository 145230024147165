.popup-subscribe {
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .2);
    background-color: #fff;
    padding: 10px;
    padding-right: 12px;
    padding-left: 20px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 20;
    display: -ms-flex;
    display: flex;
    align-items: center;

    &__text {
        width: 100%;
        margin-bottom: 0;
    }

    &__btn {
        height: 26px;
        margin-left: 33px;
    }
}
