.drop {
    color: #a29ea1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    position: relative;

    .form {
        position: absolute;
        right: 40px;
        left: 40px;
        top: 20px;

        @include respond-to-max(360px) {
            right: 0;
            left: 0;
        }

        .form__input {
            font-size: 11px;
            height: 36px;
            padding: 0 10px;
            border: solid 1px #ececec;
        }
    }

    &__title {
        font-size: rem(24px);
        margin-bottom: 10px;
        font-weight: 400;

        @include respond-to-max(360px) {
            font-size: rem(17px);
        }
    }

    &__text {
        font-size: 15px;
        padding-bottom: 20px;

        @include respond-to-max(360px) {
            font-size: rem(13px);
        }
    }

    &__btn {
        font-size: 17px;
        font-weight: 500;
        color: $base-color;
        border-radius: 32px;
        border: solid 1.5px $base-color;
        padding: 9px 34px;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
            background-color: $base-color;
            color: #fff;
        }

        input {
            display: none;
        }
    }
}
