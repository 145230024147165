.checkbox {

    &__input {
        display: none;

        &:checked + label {

            &::after {
                opacity: 1;
            }
        }
    }

    &__label {
        position: relative;
        padding-left: 30px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 20px;
            height: 20px;
            border: 1px solid #ececec;
            cursor: pointer;

            @include respond-to-max(490px) {
                width: 17px;
                height: 17px;
            }
        }

        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 20px;
            height: 20px;
            cursor: pointer;
            background: url(../images/icons/selected.svg) center no-repeat;
            opacity: 0;
            transition: opacity .4s ease;

            @include respond-to-max(490px) {
                width: 17px;
                height: 17px;
            }
        }
    }
}
