.progress {
    margin-top: 10px;

    &__bar {
        background-color: #ececec;
        border: 0;
        width: 100%;
        max-width: 300px;
        height: 10px;
        margin-bottom: 3px;

        &::-webkit-progress-bar {
            background-color: #ececec;
        }

        &::-webkit-progress-value {
            background-color: $base-color;
        }

        &::-moz-progress-bar {
            background-color: #ececec;
        }
    }

    &__text {
        font-size: 15px;
        font-weight: 500;
    }
}
