html {
  font-size: $base-font-size;
  box-sizing: border-box;
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  color: $text-color;
  line-height: 1.3;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.link {
  color: $text-color;
  font-weight: 500;

  &:hover {
    color: $base-color;
  }
}

.title {
  font-size: rem(30px);
  span {
    color: $base-color;
    font-weight: 600;
  }

  @include respond-to-max(490px) {
    font-size: rem(20px);
  }
}

.subtitle {
    font-size: rem(17px);
    line-height: 1.5;

    &_middle {
        font-size: rem(19px);
    }

    @include respond-to-max(360px) {
        font-size: 14px;
    }
}

.wrap {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
.content {
  flex-grow: 1;
  display: flex;
}

.container {
  max-width: 1920px;
  margin: 0 auto;
}

a {
  text-decoration: none;
  transition: all .3s ease;
  color: $base-color;

  &:hover {
    color: lighten($base-color, 5%);
  }
}

button,
input,
textarea {
    font-family: "Montserrat", sans-serif;
    outline: none;
    transition: all .3s ease;

}

button {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
}

.btn {
  font-size: rem(19px);
  color: #fff;
  font-weight: 600;
  text-align: center;
  border-radius: 32px;
  box-shadow: 0 10px 24px 0 rgba(12, 5, 9, .08);
  background-image: linear-gradient(to top, #00cac4, #00ddd6);
  padding: 21px rem(30px);
  width: 100%;
  max-width: 240px;
  display: inline-block;
  transition: all .3s ease;

  &:hover {
    background-image: linear-gradient(to top, #00ddd6, #00cac4);
    color: #fff;
  }

  @include respond-to-max(490px) {
    padding: 12px rem(30px);
  }

  &[disabled] {
    opacity: .3;
  }

  &_white {
    background: #fff;
    color: $text-color;

    &:hover {
        background-image: linear-gradient(to top, #00ddd6, #00cac4);
        color: #fff;
    }

    @include respond-to-max(490px) {
        padding: 12px rem(30px);
    }

    &[disabled] {
        opacity: .3;
    }
  }
  &_border {
    border: 1.5px solid $base-color;
    color: $base-color;
    padding: 11px 30px;
  }
  &_small {
    max-width: 180px;
    font-size: 17px;
    padding: 12px 30px;
  }
  &_middle {
    max-width: 250px;
    font-size: 17px;
    padding: 9px 5px;
  }
  &_icon {
    border: none;
    color: $base-color;
    box-shadow: none;
    &:hover {
      background: none;
      color: #00e4dd;
    }
  }
  &_shadow_none {
    box-shadow: none;
  }

  &_bg-red {
    font-size: rem(17px);
    background: #f75c4a;
    max-width: 260px;
    padding: 10.5px rem(20px);
  }
}

.mr{
    &_20 {
        margin-right: 20px;
    }
}

h1 {
  font-size: rem(36px);
  font-weight: 500;
}

h2 {
  font-size: rem(32px);
  font-weight: 500;
}

h3 {
  font-size: rem(28px);
  font-weight: 500;
}

h4 {
  font-size: rem(24px);
  font-weight: 500;
}

h5 {
  font-size: rem(16px);
  font-weight: 500;
}

h6 {
  font-size: rem(12px);
  font-weight: 500;
}

select {
  outline: none;
}

.select {
  border: none;
  &_text-bold {
    font-weight: 500;
  }
}
