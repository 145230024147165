.gallery {
    padding: 40px;

    @include respond-to-max(1024px) {
        padding: 20px;
    }

    @include respond-to-max(360px) {
        padding: 20px 0 0;
    }

    &__list {
        margin: 0 -1.5%;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;

        @include respond-to-max(420px) {
            margin: 0;
        }
    }

    &__item {
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07);
        margin: 0 1.5% 3%;
        width: calc(100% / 5 - 3%);
        text-align: center;
        transition: box-shadow .3s ease;
        position: relative;
        cursor: pointer;
        img {
            max-width: 100%;
        }

        &:hover {
            box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.14);

            .gallery__text {
                opacity: 1;
            }
        }

        @include respond-to-max(1500px) {
            width: 22%;
        }

        @include respond-to-max(1024px) {
            width: calc(100% / 3 - 3%);
        }

        @include respond-to-max(650px) {
            width: 47%;
        }

        @include respond-to-max(420px) {
            width: 100%;
            margin: 0 0 20px;
        }
    }

    &__text {
        font-size: 16px;
        font-weight: 500;
        position: absolute;
        bottom: 27px;
        right: 0;
        left: 0;
        text-align: center;
        transition: opacity .3s ease;
        opacity: 0;
    }
}
