.dashboard {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: calc(100% - 58px);

    &__content {
        flex-grow: 1;

        @include respond-to-max(360px) {
            padding: 0 20px;
        }

    }
    &__control {
        padding: 41px 40px;
        :not(:last-child){
            margin-right: 20px;
        }

        @include tablet {
            padding: 41px 20px;
        }

        @include phone {
            padding: 0;

            &_p20{
                padding: 20px;
            }
            .btn {
                font-size: 15px;
                margin-right: 0;
                max-width: 100%;
                border: none;
                text-align: left;
                padding: 10px 20px;
                border-bottom: 1px solid #ECECEC;
                border-radius: 0;

                &:hover {
                    background: transparent;
                    color: $base-color;
                }
            }
        }

        @include respond-to-max(360px) {
            padding: 0;
            margin: 0 -20px;

            &_p20{
                padding: 20px;
            }
        }
    }
}
