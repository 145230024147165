.popup-subscription {
    display: -ms-flex;
    display: flex;
    align-items: center;
    padding-top: 0;
    background-color: rgba(0, 0, 0, .7);

    .popup__wrap {
        max-width: 700px;
    }

    p {
        font-size: rem(17px);
        line-height: 1.65;
    }

    &__btn {
        margin: 30px 0;
    }

    &__link {
        font-size: rem(17px);
        font-weight: 500;
    }
}
