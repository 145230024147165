.sign {

    &__box {
        display: -ms-flex;
        display: flex;
        height: 100vh;
    }

    &__top {
        @include respond-to-max(490px) {
            text-align: center;
        }
    }

    &__col {
        width: 50%;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5%;
        position: relative;

        @include tablet {
            width: 100%;
        }

        @include respond-to-max(490px) {
            padding: 20px;
        }

        &:last-child {
            background: $base-color url(../images/icons/background.svg) center no-repeat;
            background-size: cover;

            @include tablet {
                display: none;
            }
        }
    }

    &__back {
        font-size: 16px;
        font-weight: 500;
        position: absolute;
        top: 45px;
        left: 45px;
        color: $base-color;
        svg {
            width: 17px;
            vertical-align: text-top;
        }

        @include tablet {
            top: 65px;
            left: 20px;

            svg {
                path {
                    fill: $text-color !important;
                }
            }

            span {
                display: none;
            }
        }
    }

    &__wrap {
        width: 100%;
        max-width: 500px;
    }

    &__text {
        font-size: rem(17px);
        letter-spacing: -.5px;
        max-width: 430px;
        margin-bottom: 35px;

        @include respond-to-max(490px) {
            font-size: rem(14px);
        }
    }

    &__steps {
        margin-bottom: 15px;
    }

    &__steps-item {
        display: inline-block;
        width: 100%;
        max-width: 100px;
        height: 2px;
        border-radius: 1px;
        background-color: #ececec;
        margin-right: 4px;

        &.active {
            background-color: $base-color;
        }
    }

    &__company {
        text-align: center;
        color: #fff;
    }

    &__company-name {
        font-size: rem(48px);
        font-weight: 700;
        letter-spacing: -1px;
        margin: -30px 0 6px;
    }

    &__company-slogan {
        font-size: rem(22px);
        font-weight: 500;
        letter-spacing: -.5px;
    }


}
