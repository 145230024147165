.sign-header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 12px 20px;
    box-shadow: 0 1px 6px 0 #f5f5f5, 0 1px 0 0 #f5f5f5;
    background-color: #fff;
    display: none;

    @include tablet {
        display: block;
    }

    &__link {
        display: -ms-flex;
        display: flex;
        align-items: center;
    }

    &__desc {
        font-size: rem(17px);
        color: $text-color;
        font-weight: 600;
        margin-left: 10px;
    }
}
