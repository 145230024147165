.plans {
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;

    &__list {
        display: -ms-flex;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 40px 0 20px;

        @include respond-to-max(360px) {
            margin: 40px 0 0;
        }
    }

    &__list-item {
        border: solid 1px #ececec;
        padding: 45px 26px 30px;
        margin: 0 15px 20px;

        &_popular {
            overflow: hidden;
            position: relative;
            background-color: $base-color;
            color: #fff;
            .btn {
                background: #fff;
                color: $text-color;
            }
        }

        @include respond-to-max(360px) {
            margin: 0 0 20px;
            width: 100%;
        }
    }

    &__list-label {
        font-size: 8px;
        color: $text-color;
        text-transform: uppercase;
        font-weight: 600;
        background-color: #ececec;
        padding: 5px 0;
        position: absolute;
        top: 5px;
        right: -30px;
        width: 100px;
        transform: rotate(45deg);
    }

    &__list-time {
        font-size: rem(20px);
        font-weight: 600;
        letter-spacing: -.63px;

        @include respond-to-max(360px) {
            font-size: 16px;
        }
    }

    &__list-price {
        font-size: rem(40px);
        font-weight: 500;

        &::before {
            content: "\0024";
            font-size: rem(20px);
            font-weight: 600;
            display: inline-block;
            vertical-align: super;
            margin-right: 2px;

            @include respond-to-max(360px) {
                font-size: rem(16px);
            }
        }

        @include respond-to-max(360px) {
            font-size: rem(32px);
        }
    }

    &__list-period {
        font-weight: 500;
        letter-spacing: -.5px;
        opacity: .5;
        margin-bottom: 35px;

        @include respond-to-max(360px) {
            font-size: 12px;
        }
    }

    &__include {
        .subtitle {
            @include respond-to-max(360px) {
                text-align: left;
            }
        }
    }
}
