.form {
    font-size: 15px;

    @include respond-to-max(490px) {
        font-size: 12px;
    }

    &__row {
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    &__group {

        &.error {
            .form__error {
                opacity: 1;
                visibility: visible;
            }
            .form__input {
                border-bottom-color: $error-color;
            }
        }
    }

    &__error {
        font-size: 10px;
        font-weight: 500;
        color: $error-color;
        margin-top: -16px;
        display: block;
        padding-left: 22px;
        opacity: 0;
        position: relative;
        visibility: hidden;
        transition: opacity .3s ease;
    }

    &__input {
        font-size: 15px;
        font-family: "Montserrat", sans-serif;
        width: 100%;
        height: 80px;
        border-color: transparent;
        border-bottom-color: #ececec;
        border-width: 1px;
        padding: 0 20px;
        background-color: transparent;

        &::-webkit-input-placeholder {
            color: #a29ea1;
        }

        &:-ms-input-placeholder {
            color: #a29ea1;
        }

        @include respond-to-max(490px) {
            height: 44px;
        }
    }

    &__btns {
        margin-top: 60px;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;

        @include respond-to-max(490px) {
            display: block;
            text-align: center;
            margin-top: 40px;
        }

        .btn {
            width: 48%;

            @include respond-to-max(490px) {
                min-width: 240px;
            }


            &:first-child {
                margin-right: 4%;

                @include respond-to-max(490px) {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
        }

    }

    &__label {
        font-size: 12px;
        font-weight: 500;
        color: #a29ea1;
        margin-bottom: 10px;
        display: block;
    }

    &__textarea {
        font-size: 12px;
        width: 100%;
        border: solid 1px #ececec;
        min-height: 85px;
        padding: 10px;
        resize: none;
    }
}
