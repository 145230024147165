.user {
  display: flex;
  align-items: center;
  &__logo {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    overflow: hidden;
    display: block;
    margin-right: 12px;
    @include respond-to-max(1024px) {
      width: 20px;
      height: 20px;
      img {
        height: 20px;
        width: 20px;
      }
    }
  }
  &__name {
    font-size: 19px;
    font-weight: bold;
    line-height: 28px;
    color: #0f0f0f;
    position: relative;
    margin-right: 20px;
    letter-spacing: -.5px;
    @include respond-to-max(375px) {
        display: none;
    }
  }
  &__name-icon {
    position: absolute;
    top: 0;
    right: -18px;
  }
}
