.plans-include {

    &__list {

        @include phone {
            margin: 0 auto;
            max-width: 230px;
        }
    }

    &__item {
        font-size: rem(19px);
        letter-spacing: -.5px;
        position: relative;
        text-align: left;
        display: inline-block;
        width: 34%;
        margin-bottom: 10px;

        &::before {
            content: "";
            display: inline-block;
            width: 14px;
            height: 11px;
            margin-right: 9px;
            background: url("../images/icons/check.svg") center no-repeat;
        }
        @include phone {
            display: block;
            width: auto;
        }

        @include respond-to-max(360px) {
            font-size: 13px;
        }
    }

}
