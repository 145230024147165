.catalog-mobile {
    display: none;

    @include respond-to-max(360px) {
        margin: 0 -20px;
    }

    @include phone {
        display: block;
    }

    &__head {
        font-size: 12px;
        font-weight: 500;
        display: -ms-flex;
        display: flex;
        border-bottom: solid 1px #ececec;
    }

    &__head-item {
        flex: 1;
        text-align: center;
        padding: 10px;
        border-right: 1px solid #ececec;
        cursor: pointer;

        &:last-child {
            border-right: none;
        }
    }

    &__body-row {
        padding: 5px 0 0;

        &:nth-child(even) {
            background-color: #f5f5f5;
        }
    }

    &__body-wrap {
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        padding: 5px 20px;

        &:last-child {
            border-top: solid 1px #ececec;
            margin-top: 5px;
            padding-top: 10px;
            padding-bottom: 10px;
            .catalog__item-btn {
                padding: 0;
            }
        }
    }

    &__body-item {

        &:first-child {
            font-weight: 500;
        }
    }

}
