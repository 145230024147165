.user-account {
    padding: 40px 0 50px;
    height: 100%;

    &__form {
        height: 100%;
        display: -ms-flex;
        display: flex;
        flex-direction: column;

        .form__input {
            height: 56px;
        }

        &_type-label {

            .form__group {
                margin-bottom: 20px;
            }

            .form__input {
                height: 36px;
                border: solid 1px #ececec;
                padding: 0 10px;
            }

            .form__label {
                margin-bottom: 6px;
                opacity: .5;
            }
        }
    }

    &__form-box {
        display: -ms-flex;
        display: flex;

        @include phone {
            display: block;
        }
    }

    &__col {
        flex: 1;
        margin: 0 2.5%;

        @include respond-to-max(360px) {
            margin: 0;
        }
    }

    &__form-btn {
        margin-top: auto;
        text-align: center;

        @include phone {
            margin-top: 30px;
        }
    }
}
