.popup {
  position: fixed;
  z-index: 50;
  padding-top: 60px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(#000, .5);
  overflow-y: scroll;

  &.popup-images {
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
  }

  @include respond-to-max(490px) {
    padding-top: 0;
  }

  &__wrap {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    background-color: #fff;
    padding: 4% 6% 4%;
    position: relative;
    line-height: 1.45;

    @include respond-to-max(490px) {
      padding: 20px;
    }

    .title {
      text-align: center;
    }

    &.horizontal {
      max-width: 1050px;
    }
  }

  &__close {
    width: 26px;
    height: 26px;
    background: url(../images/icons/close.svg) center no-repeat;
    background-size: 14px;
    position: absolute;
    top: 20px;
    left: 20px;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
  }

  &__row {
    display: -ms-flex;
    display: flex;
    align-items: center;
  }

  &__col {
    &:first-child {
      flex: 1;
      margin-right: 50px;
    }
  }

  &__item {
    max-width: 500px;
    margin: 0 auto;
    border-bottom: solid 1px #ececec;
    position: relative;
    &__item {
      max-width: 500px;
      margin: 0 auto;
      padding: 30px 20px;
      border-bottom: solid 1px #ececec;

      &.popup__select select {
        border: none;
        position: relative;
        padding: 0;
        font-size: 15px;
      }

      &.popup__select {
        position: relative;

        &::after {
          z-index: 3;
          right: 28px;
          top: 48%;
        }
      }
    }
  }

  &__create-btn {
    margin: 0 auto;
    display: block;
    margin-top: 50px;
  }
}
