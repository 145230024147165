.counter {
    display: -ms-flex;
    display: flex;
    align-items: center;

    &__btn {
        font-size: 22px;
        color: $base-color;
        line-height: 1;

        &:hover {
            color: lighten($base-color, 5%);
        }

        &[disabled] {
            opacity: .3;
        }
    }

    &__number {
        font-weight: 500;
        margin: 0 6px;
    }
}
