.create {
  &__select {
    padding: 31px 20px;
    border: none;
    position: relative;
    border-bottom: 1px solid #ececec;

    select {
      min-height: 36px;
      padding-left: 8px;
      font-size: 11px;
      width: 100%;
      font-size: 15px;
      color: #a29ea1;
    }
    &::after {
      z-index: 3;
      right: 28px;
      top: 48%;
    }
  }
}
