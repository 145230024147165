.plan {
    margin: 20px 0 40px;
    .plans__list-item {
        border-color: $text-color;
        min-width: 200px;
        display: inline-block;
        margin: 0;
        padding: 37px 26px;
    }
    .plans__list-period {
        margin-bottom: 0;
    }
}
