.footer {
  padding: 20px 0;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  .header-step__item {
    &:last-child{
      border: none;
      margin-right: 0;
      padding-right: 0;
    }
  }
}
