.menu {
    font-size: 15px;
    font-weight: 500;
    background: #fcfcfc;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    position: relative;
    min-width: 58px;
    transition: all .3s ease;

    &.open {
        width: 100%;
        max-width: 260px;
        align-items: flex-start;

        .menu__box {
            padding-right: 40px;
            padding-left: 40px;
        }

        .menu__item-name {
            width: auto;
            margin-left: 12px;
        }

        .menu__logout-name {
            width: auto;
            margin-left: 12px;
        }

        & .menu__btn svg {
            transform: rotate(180deg);
        }
    }

    &__box {
        padding: 40px 0 60px;
    }

    &__item {
        display: flex;
        align-items: center;
        color: #a29ea1;
        margin-bottom: 40px;

        svg path + path {
            transition: fill .3s ease;
        }

        &:hover {
            svg path + path {
                fill: $base-color;
            }
        }

        &.active {
            color: $text-color;

            svg path + path {
                fill: $base-color;
            }
        }
    }

    &__item-name {
        text-transform: capitalize;
        width: 0;
        overflow-x: hidden;
        transition: all .3s ease;
    }

    &__item-icon {
        height: 20px;
    }

    &__logout {
        display: flex;
        color: $text-color;

        svg path + path {
            transition: fill .3s ease;
        }

        &:hover {
            svg path + path {
                fill: $base-color;
            }
        }
    }

    &__logout-name {
        white-space: nowrap;
        width: 0;
        overflow-x: hidden;
        transition: all .3s ease;
    }

    &__btn {
        position: absolute;
        height: 24px;
        width: 24px;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, .12);
        border-radius: 100%;
        right: -12px;
        top: 43.2%;
        display: flex;
        background: #fff;
        transition: all .3s ease;

        & svg {
            transition: all .3s ease;
            margin: auto;
        }

        & svg path {
            transition: all .3s ease;
            margin: auto;
        }

        &:hover svg path {
            fill: $base-color;
        }
    }

    @include phone {
        width: 100%;
        max-width: 260px;
        align-items: flex-start;
        position: fixed;
        bottom: 0;
        top: 68px;
        z-index: 15;
        transform: translate3d(-100%, 0, 0);

        &.open {
            transform: translate3d(0, 0, 0);
        }


        .menu__box {
            padding-right: 40px;
            padding-left: 40px;
        }

        .menu__item-name {
            width: auto;
            margin-left: 12px;
        }

        .menu__logout-name {
            width: auto;
            margin-left: 12px;
        }

        & .menu__btn {
            display: none;
        }
    }
}
