.popup-images {

    .popup__wrap {
        padding-top: 20px;
    }

    &__top {
        text-align: center;
        margin-bottom: 40px;
    }

    &__name {
        font-size: rem(20px);
        margin-bottom: 20px;
        display: block;
        letter-spacing: -.63px;
    }

    &__count {
        display: -ms-flex;
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        &.form__input {
            border-bottom: 1px solid #ececec;
            margin-bottom: 0;
            .popup-images__text {
                font-size: 15px;
            }
        }
    }

    &__text {
        font-size: 12px;
        color: #a29ea1;
        margin-right: 16px;
    }
}
