.images {
    padding: 40px;
    height: 100%;
    display: -ms-flex;
    display: flex;
    flex-direction: column;

    @include respond-to-max(440px) {
        padding: 19px;
    }

    @include respond-to-max(360px) {
        padding: 0 0 20px;
    }

    &__top {
        font-size: rem(17px);
        padding: 20px 0;
        text-align: right;
        border-top: solid 1px #ececec;
    }

    &__list {
        display: -ms-flex;
        display: flex;
        margin: 0 -10px;
        flex-wrap: wrap;

        @include respond-to-max(440px) {
            margin: 0 -4px;
        }

        &.options {
            .images__count {
                display: block;
            }
        }

        &.select {
            .images__checkbox {
                display: block;
            }
        }
    }

    &__item {
        margin: 0 10px 20px;
        text-align: center;
        position: relative;

        &.selected {
            .images__pic {

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    background-color: $base-color;
                    opacity: .1;
                }
            }

        }

        @include respond-to-max(440px) {
            margin: 0 4px 10px;
        }
    }

    &__name {
        @include respond-to-max(440px) {
            font-size: 10px;
        }
    }

    &__count {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        z-index: 5;
        display: none;

        &.open {
            .images__count-dropdown {
                opacity: 1;
                visibility: visible;
                transform: translate3d(-50%, 10px, 0);
            }
        }
    }

    &__count-number {
        color: #fff;
        position: absolute;
        padding-right: 3px;
        padding-top: 1px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        font-size: 8px;
    }

    &__count-dropdown {
        position: absolute;
        top: 100%;
        left: 50%;
        z-index: 10;
        transform: translate3d(-50%, 20px, 0);
        font-size: 15px;
        background-color: #fff;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
        text-align: left;
        padding: 20px;
        width: 215px;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;

        &::before {
            content: "";
            position: absolute;
            left: 48%;
            bottom: 100%;
            border: 5px solid transparent;
            border-bottom: 10px solid #fff;


        }
    }

    &__count-dropdown-wrap {
        display: -ms-flex;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    &__count-text {
        margin-right: 8px;
    }

    &__count-add {
        color: $base-color;
        transition: color .3s ease;

        &:hover {
            color: lighten($base-color, 5%);

            &::before {
                border-color: lighten($base-color, 5%);
            }
        }

        &::before {
            content: "+";
            font-size: 16px;
            display: inline-block;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            border: solid 1px $base-color;
            border-radius: 50%;
            margin-right: 9px;
            transition: border-color .3s ease;
        }
    }

    &__count-input {
        border: none;
        width: 100%;
    }

    &__checkbox {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 5;
        display: none;
    }

    &__checkbox-input {
        display: none;

        &:checked + label {

            &:before {
                opacity: 1;
            }
        }
    }

    &__checkbox-label {
        width: 24px;
        height: 24px;
        display: block;
        background: url(../images/icons/unselected.svg) 0 0 no-repeat;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 24px;
            height: 24px;
            background: url(../images/icons/selected2.svg) 0 0 no-repeat;
            opacity: 0;
            transition: opacity .3s ease;
            cursor: pointer;
        }
    }

    &__pic {
        margin-bottom: 8px;
        width: 160px;
        height: 160px;
        border: solid 1px #ececec;
        position: relative;

        img {
            max-width: 100%;
        }

        @include respond-to-max(440px) {
            width: 87px;
            height: 87px;
        }
    }

    &__btn {
        text-align: center;
        margin-top: auto;

        .btn {
            @include respond-to-max(440px) {
                max-width: 100px;
                padding: 6px 20px;
            }
        }
    }
}
