.bad-request {
    color: $text-color;
    font-size: rem(19px);
    padding: 0 20px;

    @include respond-to-max(360px) {
        padding: 0;
    }

    .title {
        margin-bottom: 2px;
    }

    &__subtitle {
        color: #cd0000;
        text-align: center;
    }

    &__text {
        opacity: .7;
        line-height: 1.47;
        letter-spacing: -.5px;
        max-width: 690px;
        text-align: center;

        p {
            margin-bottom: 0;
        }

        a {
            font-weight: 500;
        }
    }
}
