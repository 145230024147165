.catalog {
  padding: 0 40px;
  border: none;

  &::-webkit-scrollbar {
      height: 6px;
  }

  &::-webkit-scrollbar-track {
      background-color: #F1F1F1;
  }

  &::-webkit-scrollbar-thumb {
      background-color: $base-color;
  }

  @include tablet {
      padding: 0 20px;
  }

  @include respond-to-max(1450px) {
      overflow-x: auto;
      overflow-y: hidden;
  }

  @include phone {
      display: none;

      &_show {
          display: block;
      }
  }

  @include respond-to-max(360px) {
      padding: 0;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 9px;
  }

  &__container_padding-none {
    padding: 0;
  }

  &__head {
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    padding-top: 11px;
    padding-bottom: 5px;
  }

  &__select select {
    position: relative;
    background: none;
    appearance: none;
    min-width: 100px;

    &::-ms-expand {
      display: none;
    }
  }

  &__select {
    &::after {
      content: "";
      position: absolute;
      background: url("../img/icons/dropdown-arrow.svg") no-repeat;
      width: 13px;
      height: 10px;
      top: 41%;
      right: 11px;
      z-index: -1;
    }
  }

  &__head-select select {
    border: none;
    min-width: 75px;
    position: relative;
  }

  &__head-select {
    position: relative;
    max-width: 75px;

    &::after {
      content: "";
      background: url("../img/icons/polygon.svg") no-repeat;
      top: 34%;
      right: 1px;
    }
  }

  &__head_border-bottom {
    border-top: none;
  }

  &__select-arrow {
    position: absolute;
    top: 9px;
    right: 10px;
  }

  &__select-icon {
    position: absolute;
    right: 0;
    top: -2px;
    z-index: -1;
  }

  &__select_bold select {
    font-weight: 600;
  }

  &__item {
    flex: 1 0 100.5px;
    margin-right: 10px;
    position: relative;

    &:last-child {
        margin-right: 0;
    }
  }

  &__item_end {
    text-align: end;
    margin: 0;
  }
  &__item_wide {
    min-width: 182px;
    margin-right: 10px;
    text-align: right;
  }

  &__item-title {
    font-size: 14px;
    margin: 4px 0;
  }

  &__item-btn {
    padding: 0 5px;
    position: relative;
    display: inline-flex;
    align-self: center;

    &:last-child {
      padding-right: 0;
    }

    svg path {
      transition: all .3s ease;
    }

    &:hover {
      svg path {
        fill: $base-color;
      }

       .catalog__item-btn_text {
        opacity: 1;
        visibility: visible;
      }
    }

    &:active {
      svg path {
        fill: #a29ea1;
      }
    }
  }

  &__item-btn_text {
    padding: 10px 20px;
    position: absolute;
    background: #ececec;
    color: #0f0f0f;
    right: -72%;
    bottom: -37px;
    white-space: nowrap;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .07);
    border: solid 1px #ececec;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 500;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
  }

  &__filters-btn {
    display: block;
    margin-right: 0;
    font-weight: 400;
    line-height: 18px;
  }

  &__filters {
    color: #a29ea1;
    font-weight: 500;
    line-height: 15px;
    padding-top: 5px;
    padding-bottom: 12px;
  }

  &__filters-title {
    padding-bottom: 5px;
    font-size: 12px;
    opacity: .5;
  }

  &__filters-item {
    min-height: 36px;
    padding-left: 8px;
    font-size: 11px;
    border: solid 1px #ececec;
    width: 100%;
  }

  &__result {
    padding-top: 11px;
    padding-bottom: 15px;
  }

  &__result_narrow {
    padding-top: 8px;
    padding-bottom: 3px;
  }

  &__result_middle {
    padding-top: 10px;
    padding-bottom: 12px;
  }

  &__result_bg-grey-light {
    background-color: #fcfcfc;
  }

  &__result_bg-grey {
    background-color: #f5f5f5;
  }

  &__result_bg_opacity {
    opacity: .1;
  }

  &__result-top {
    align-items: flex-start;
  }

  &-popup {
    position: absolute;
    background: #ffffff;
    top: -372%;
    left: -106%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    padding: 15px;
    padding-bottom: 5px;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .15);
    &.open {
      opacity: 1;
      visibility: visible;
    }

    &::before {
      content: "";
      position: absolute;
      right: 23%;
      bottom: -15px;
      border: 5px solid transparent;
      border-top: 10px solid #fff;
    }
  }

  &-popup__title {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #0f0f0f;
    padding-bottom: 10px;
  }

  &-popup__link {
    height: 10px;
    font-family: Montserrat;
    font-size: 8px;
    font-weight: 500;
    text-align: center;
    color: #a29ea1;
  }

  &-popup__buttons {
    display: flex;
  }

  &-popup__button {
    font-size: 10px;
    padding: 10px 20px;
    font-weight: 600;
  }

  &-popup__button_red {
    color: #ff3b30;

    &:hover {
      color: #ff3a3062;
    }
  }

  &__link {
    position: relative;
  }
  &__btn button {
    font-size: 15px;
    padding: 6px 24px;
  }

  &__btn-link {
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding-left: 40px;
    padding-left: 34px;
    line-height: 26px;
  }

  &__btn-icon svg {
    position: absolute;
    top: 0px;
    left: 1px;
    height: 26px;
  }

  &__drop {
    height: calc(100% - 157px);
  }

  &__checkbox-input {
    display: none;
    transition: all .3s ease;
  }

  &__checkbox-input:checked + label::before {
    transition: all .3s ease;
    background-color: #00cac4;
  }

  &__checkbox {
    label {
      font-size: 15px;
      color: #0f0f0f;
    }

  }

  &__import .catalog__item-text {
    padding-left: 8px;
  }

  &__import-btn {
    max-width: 180px;
    padding: 13px 2px;
  }

  &__footnote {
    font-size: 17px;
    line-height: 28px;
    margin-top: 17px;
    letter-spacing: -.5px;
    margin-bottom: 37px;
  }

  &__nav {
    display: inline-flex;
    box-shadow: inset 0 -2px 0 0 #ececec;
    margin-bottom: 47px;

    @include respond-to-max(550px) {
        display: block;
    }
  }

  &__nav-item {
    font-size: 17px;
    font-weight: 500;
    width: 150px;
    text-align: center;
    padding-bottom: 10px;
    transition: all .3s ease;

    &:hover {
      box-shadow: inset 0 -2px 0 0 #02cac4;
      cursor: pointer;
    }

    @include respond-to-max(550px) {
        width: 100%;
        margin-bottom: 10px;
    }
  }

  &__nav-item.active {
    box-shadow: inset 0 -2px 0 0 #02cac4;
  }
  &__attributes {
    padding: 15px;
    padding: 15px 11px;
  }
  &__attributes-link {
    padding-left: 20px;
  }
  &__attribytes-item {
    max-width: 440px;
  }
}
