.header {
    width: 100%;
    padding: 20px 40px;
    box-shadow: 0 2px 12px 0 #f5f5f5, 0 1px 0 0 #f5f5f5;
    @include tablet {
        padding: 20px;
    }

    &__wrap {
        display: flex;
        justify-content: space-between;

        &_filters {
            justify-content: flex-start;

            @include phone {
                display: block;
            }

            .catalog__container {
                @include phone {
                    display: block;
                }

                .catalog__item {
                    @include phone {
                        margin-right: 0;
                        margin-bottom: 5px;
                    }
                }
            }

            .header__item {

                &:last-child {
                    flex: 1;
                    margin-left: 7%;

                    @include phone {
                        margin-left: 0;
                        margin-top: 10px;
                    }

                    .catalog__filters {
                        width: 100%;
                        max-width: 830px;
                    }

                    .catalog__item {
                        flex: 1 0 200px;

                        @include tablet {
                            flex: 1 0 100px;
                        }
                    }
                }
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
    }

    &-logo {
        height: 40px;
        width: 35px;
        display: inline-block;
        margin-right: 20px;
        @include tablet {
            height: 20px;
            width: 20px;
            margin-right: 10px;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    &-step {

        &__title {
            font-size: 15px;
            font-weight: bold;
            line-height: 28px;
            letter-spacing: -.39px;
        }

        &__box {
            display: flex;
            align-items: center;
            @include tablet {
                display: none;
            }
        }

        &__item {
            display: flex;
            font-size: 12px;
            height: 12px;
            color: #a29ea1;
            padding-right: 8px;
            border-right: 1px solid #a29ea1;
            margin-right: 10px;

            &-icon {
                padding-right: 6px;
            }
        }

        &__info {
            height: 18px;
            width: 18px;
        }
    }

    &-buttons {
        margin-right: 50px;
        @include respond-to-max(1024px) {
            display: none;
        }

        &__link {
            display: inline-block;
            padding: 0 10px;

            & svg path {
                transition: all 0.3s ease;
            }

            &:hover svg path {
                transition: all 0.3s ease;
                fill: $base-color;
            }

            &.disabled {
                cursor: default;

                & svg path {
                    fill: #ececec;
                }

                &:hover svg path {
                    fill: #ececec;
                }
            }
        }
    }
}
